let settingsData = null

export async function getAPIURL () {
  const currentHostName = window.location.host

  if (settingsData == null) {
    const path = window.location.pathname.replace('sender', '')
    const settingsUrl = window.location.protocol + '//' + currentHostName + path + 'settings.json'
    console.log('Loading client settings from:' + settingsUrl)
    const response = await fetch(settingsUrl)
    settingsData = await response.json()
    console.log('Loaded app settings:' + JSON.stringify(settingsData))
  }
  let apiUrl = ''
  if (settingsData.api_target.length > 0) {
    // in the upgrade scenario the client will be served via /upgrade/api path  (from an upgraded version of the backend system)
    apiUrl = window.location.protocol + '//' + currentHostName + '/' + settingsData.api_target + '/api/'
  } else {
    apiUrl = window.location.protocol + '//' + currentHostName + '/api/'
  }
  return apiUrl
}
