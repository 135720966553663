import React from 'react'
import ReceiverPanel from './receiverPanel'
import VideoPanel from '../videoPanel'
import Typography from '@mui/material/Typography'
import { useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setUrlPin } from '../sessionSlice'

export default function Receiver () {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const pinFromSearchParameter = searchParams.get('PIN')
  dispatch(setUrlPin(pinFromSearchParameter))

  return (
    <div>
      <Typography variant='h4' gutterBottom component='div' style={{ color: 'White' }}>
        Screen Sharing
      </Typography>
      <Typography variant='h6' gutterBottom component='div' style={{ color: 'White' }}>
        Disclaimer on usage
      </Typography>
      <div className='container'>
        <ReceiverPanel />
        <div className='break' />
        <VideoPanel />
      </div>
    </div>
  )
};
