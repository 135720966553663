import { configureStore, combineReducers } from '@reduxjs/toolkit'
import sessionSlice from './sessionSlice'

const root = combineReducers({
  session: sessionSlice
})

export default configureStore({
  reducer: {
    root
  }
})
