import './index.css'
import React from 'react'
import { createRoot } from 'react-dom/client'
import Receiver from './receiver/Receiver'
import Sender from './sender/Sender'
import store from './store'
import { Provider } from 'react-redux'
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme'
import { CookiesProvider } from 'react-cookie'
import CookieConsent from 'react-cookie-consent'
import Typography from '@mui/material/Typography'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

const container = document.getElementById('root')
const root = createRoot(container)

// eslint-disable-next-line no-undef
console.log('Running version:' + VERSION)
// eslint-disable-next-line no-undef
console.log('Last commitdate:' + LASTCOMMITDATETIME)

// Telemetry

const appInsights = new ApplicationInsights({
  config: {
    connectionString: 'InstrumentationKey=1eaf314b-aea0-4528-b55e-40a20b07de38;IngestionEndpoint=https://eastus2-3.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/'
  }
})
appInsights.loadAppInsights()
appInsights.trackPageView()

let addPath = ''
if (window.location.pathname.includes('dev')) {
  addPath = '/dev'
}

const App = () => {
  return (
    <div>
      <CookiesProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route path={addPath + '/'} element={<Receiver />} />
              <Route path={addPath + '/sender'} element={<Sender />} />
            </Routes>
          </BrowserRouter>
          <CookieConsent>
            <Typography sx={{ mb: 1.5 }} color='white'>
              This website uses cookies to enhance the user experience.
            </Typography>
          </CookieConsent>
        </ThemeProvider>
      </CookiesProvider>
    </div>
  )
}

window.onload = () => {
  console.log('Rendering')
  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  )
}
