import React, { Component } from 'react'
import { connect } from 'react-redux'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import PropTypes from 'prop-types'

class VideoPanel extends Component {
  constructor (props) {
    super(props)
    this.videoDivRef = React.createRef()

    this.setVideo = () => {
      console.log('setVideo in videopanl')

      if (this.videoDivRef.current != null) {
        if (window.$videoView != null) {
          this.videoDivRef.current.innerHTML = ''
          this.videoDivRef.current.appendChild(window.$videoView)
        } else {
          this.videoDivRef.current.innerHTML = ''
        }
      }
    }
  }

  // setting Ref below to be able to appendchild in the setVideo function (se above) to the real DOM (to add the videostreamcomponent)
  render () {
    const isVideoLoading = this.props.isVideoLoading

    this.setVideo()

    return (
      <div>
        <Card sx={{ minWidth: 600, minHeight: 250 }}>
          <CardContent>
            <Typography variant='h5' component='div'>
              {(window.$videoView == null && !isVideoLoading) ? 'No video' : ''}
            </Typography>
            <Typography variant='h5' component='div'>
              {isVideoLoading ? <div style={{ display: 'inline-flex', gap: '12px' }}><div>Loading video</div><CircularProgress size={20} /></div> : ''}
            </Typography>

            <div ref={this.videoDivRef} />
          </CardContent>
        </Card>
      </div>
    )
  }
}

VideoPanel.propTypes = {
  videoDivRef: PropTypes.instanceOf(React.RefObject),
  isVideoLoading: PropTypes.bool
}

function mapStateToProps (state) {
  const showVideo = state.root.session.showVideo
  const isVideoLoading = state.root.session.isVideoLoading

  return {
    showVideo,
    isVideoLoading
  }
}

export default connect(mapStateToProps)(VideoPanel)
