import { createSlice } from '@reduxjs/toolkit'
import { CallClient, VideoStreamRenderer } from '@azure/communication-calling'
import { AzureCommunicationTokenCredential } from '@azure/communication-common'
import { getAPIURL } from './toolkit'

let call
let callClient
let callAgent

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    status: 'Disconnected',
    pin: '',
    badPin: false,
    guid: '',
    connected: false,
    call: null,
    participantCount: 0,
    screenSharingActive: false,
    isConnecting: false,
    activelyDisconnected: false,
    parametersJSON: '',
    isMuted: true,
    role: '',
    urlPin: '',
    isVideoLoading: false
  },
  reducers: {
    setSessionStatus (state, action) {
      state.status = action.payload
    },
    setConnected (state, action) {
      state.connected = action.payload
    },
    setPIN (state, action) {
      state.pin = action.payload
    },
    setGUID (state, action) {
      state.guid = action.payload
    },
    setParticipantCount (state, action) {
      state.participantCount = action.payload
    },
    setScreenSharingActive (state, action) {
      state.screenSharingActive = action.payload
    },
    setIsConnecting (state, action) {
      state.isConnecting = action.payload
    },
    setActivelyDisconnected (state, action) {
      state.activelyDisconnected = action.payload
    },
    setParametersJson (state, action) {
      state.parametersJSON = action.payload
    },
    setMuted (state, action) {
      state.isMuted = action.payload
    },
    setShowVideo (state, action) {
      state.showVideo = action.payload
    },
    setIsVideoLoading (state, action) {
      state.isVideoLoading = action.payload
    },
    setRole (state, action) {
      state.role = action.payload
    },
    setBadPIN (state, action) {
      state.badPIN = action.payload
    },
    setUrlPin (state, action) {
      if (action.payload != null) {
        state.urlPin = action.payload
        state.pin = action.payload
      }
    }

  }
})

export const { setUrlPin, setBadPIN, setRole, setShowVideo, setIsVideoLoading, setSessionStatus, setConnected, setPIN, setGUID, setParticipantCount, setScreenSharingActive, setIsConnecting, setActivelyDisconnected, setParametersJson, setMuted } = sessionSlice.actions

export function createSession () {
  return async function createSession (dispatch, getState) {
    try {
      dispatch(setIsConnecting(true))

      callClient = new CallClient()
      const parameters = getState().root.session.parametersJSON
      console.log('Creating session with cookie pin:' + parameters.cookiePIN)
      const encodedParams = encodeURIComponent(JSON.stringify(parameters))

      // get an access token to use
      if (parameters.cookiePIN != null && parameters.cookiePIN.length > 0) {
        dispatch(setSessionStatus('Trying to join existing session'))
      } else {
        dispatch(setSessionStatus('Creating new session'))
      }

      // base64 encode to not get firewall triggers
      const base64Encoded = window.btoa(encodedParams)

      const apiUrl = await getAPIURL()

      const tokenURL = apiUrl + 'createTokenAndSession?info=' + base64Encoded
      console.log('Creating session with URL:' + tokenURL)
      const response = await fetch(tokenURL)

      const responseString = await response.text()
      const responseJSON = JSON.parse(responseString)

      const token = responseJSON.initiatingUserToken
      const tokenCredential = new AzureCommunicationTokenCredential(token)

      console.log('Token:' + tokenCredential)
      callAgent = await callClient.createCallAgent(tokenCredential)
      window.$callAgent = callAgent

      const callPIN = responseJSON.pin
      dispatch(setPIN(callPIN))

      const callGUID = responseJSON.guid
      dispatch(setGUID(callGUID))

      const context = { groupId: callGUID }
      // const permissions = await (await callClient.getDeviceManager()).askDevicePermission({ audio: true, video: false })

      const callOptions = { audioOptions: { muted: true } }
      call = await callAgent.join(context, callOptions)
      window.$call = call

      subscribeToCall(call, dispatch, getState)

      call.on('totalParticipantCountChanged', () => { dispatch(setParticipantCount(call.totalParticipantCount)) })
      call.on('isScreenSharingOnChanged', () => { dispatch(setScreenSharingActive(call.isScreenSharingOn)) })

      // Set state as connected
      if (parameters.cookiePIN != null && parameters.cookiePIN.length > 0) {
        if (parameters.cookiePIN === callPIN) {
          dispatch(setSessionStatus('Connected to existing session'))
        } else {
          dispatch(setSessionStatus('Could not rejoin, connected to new session'))
        }
      } else {
        dispatch(setSessionStatus('Connected new session'))
      }

      dispatch(setActivelyDisconnected(false))
      dispatch(setParticipantCount(call.totalParticipantCount))
      dispatch(setConnected(true))
      dispatch(setIsConnecting(false))
    } catch (error) {
      console.log(error)
      // failed connect - use disconnect to reset state
      dispatch(senderDisconnect())
    }
    dispatch(setIsConnecting(false))
  }
}

export function senderDisconnect () {
  return async function disconnect (dispatch, getState) {
    try {
      // TODO: add hangup for all?
      if (call != null) {
        const options = { forEveryone: true }
        call.hangUp(options)
        window.$callAgent.dispose()
      }
      const apiURL = await getAPIURL()
      const endSessionURL = apiURL + 'endSession?pin=' + getState().root.session.pin

      await fetch(endSessionURL)
    } catch (error) { console.log(error) }
    dispatch(setSessionStatus('Session ended'))
    dispatch(setConnected(false))
    dispatch(setParticipantCount(0))
    dispatch(setPIN(''))
    dispatch(setActivelyDisconnected(true))
    dispatch(setScreenSharingActive(false))
  }
}

export function receiverDisconnect () {
  return async function disconnect (dispatch, getState) {
    try {
      window.$call.hangUp()
      window.$callAgent.dispose()
      dispatch(setSessionStatus('Disconnected'))
      dispatch(setConnected(false))
      dispatch(setParticipantCount(0))
      window.$videoView = null
      dispatch(setIsVideoLoading(false))
      dispatch(setActivelyDisconnected(true))
      window.$call = null
    } catch (error) { }
  }
}

export function shareScreen () {
  return async function createSession (dispatch, getState) {
    console.log('shareScreen called')
    dispatch(setShowVideo(false))
    try {
      await call.startScreenSharing()
      // dispatch(showLocalVideo())
    } catch (error) {
      console.log(error)
    }
  }
}

/*
  This whole piece is a bit of a hack since I havent found a proper way to get hold of the local screensharing videostream
  have extended a question to microsoft awaiting their response
*/
export function showLocalVideo () {
  return async function showVideo (dispatch, getState) {
    // TODO: look for proper way to do this

    let rawStream
    if (call.tsCall.sharedScreenHandle != null && call.isScreenSharingOn) {
      // rawStream = call.tsCall.sharedScreenHandle.mediaStream.rawMediaStream

      const mediaTrack = call.tsCall.sharedScreenHandle.mediaStream.mediaTrack

      // TODO: If imported from the azure sdk this breaks with complaints about missing constructor
      // if left without import it seems to resolved properly runtime
      rawStream = new MediaStream([mediaTrack])

      if (rawStream != null) {
        const videoElement = document.createElement('video')
        videoElement.srcObject = rawStream
        await videoElement.play()

        window.$videoView = videoElement

        dispatch(setShowVideo(true))
        // setting on window since its not serializable, reduct complains otherwise

        window.$currentVideoStream = rawStream
      }
    }
  }
}

export function joinSession () {
  return async function joinSession (dispatch, getState) {
    const pin = getState().root.session.pin
    try {
      dispatch(setIsConnecting(true))
      callClient = new CallClient()

      // get an access token to use
      dispatch(setSessionStatus('Joining session'))
      const infoObject = {}
      infoObject.PIN = pin

      const encodedParams = encodeURIComponent(JSON.stringify(infoObject))
      // base64 encode to not get firewall triggers
      const base64Encoded = window.btoa(encodedParams)

      const apiUrl = await getAPIURL()
      const sessionURL = apiUrl + 'joinSession?info=' + base64Encoded

      const response = await fetch(sessionURL)
      const responseJSON = await response.json()

      if (responseJSON.status !== 'OK') {
        // no guid found
        dispatch(setSessionStatus('No session available for that PIN'))
        dispatch(setConnected(false))
        dispatch(setParticipantCount(0))
        window.$videoView = null
        dispatch(setIsVideoLoading(false))
        dispatch(setIsConnecting(false))
        dispatch(setBadPIN(true))
      } else {
        dispatch(setSessionStatus('Creating call agent'))
        const tokenCredential = new AzureCommunicationTokenCredential(responseJSON.token)
        callAgent = await callClient.createCallAgent(tokenCredential)
        window.$callAgent = callAgent

        const guid = responseJSON.GUID
        dispatch(setBadPIN(false))
        dispatch(setGUID(guid))
        dispatch(setSessionStatus('Joining session'))
        const context = { groupId: guid }

        const callOptions = { audioOptions: { muted: true } }
        call = await callAgent.join(context, callOptions)
        window.$call = call
        subscribeToCall(call, dispatch, getState)

        call.on('totalParticipantCountChanged', () => { dispatch(setParticipantCount(call.totalParticipantCount)) })
        call.on('isScreenSharingOnChanged', () => { dispatch(setScreenSharingActive(call.isScreenSharingOn)) })
        // call.on('remoteParticipantsUpdated', () => { dispatch(stateChanged(call.state)) })

        dispatch(setSessionStatus('Connected'))
        dispatch(setParticipantCount(call.totalParticipantCount))
        dispatch(setConnected(true))
        dispatch(setIsConnecting(false))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

const subscribeToCall = (call, dispatch, getState) => {
  try {
    // Inspect the initial call.id value.
    console.log(`Call Id: ${call.id}`)
    // Subsribe to call's 'idChanged' event for value changes.
    call.on('idChanged', () => {
      console.log(`Call Id changed: ${call.id}`)
    })

    // Inspect the initial call.state value.
    console.log(`Call state: ${call.state}`)

    // Subscribe to call's 'stateChanged' event for value changes.
    call.on('stateChanged', async () => {
      console.log(`Call state changed: ${call.state}`)
      if (call.state === 'Disconnected') {
        if (!getState().root.session.activelyDisconnected) {
          // disconnected by remote ending of session
          dispatch(setSessionStatus('Disconnected'))
          dispatch(setConnected(false))
          dispatch(setParticipantCount(0))
          window.$videoView = null
          dispatch(setIsVideoLoading(false))
          window.$call.dispose()
          window.$call = null
        }
      }
    })

    // Inspect the call's current remote participants and subscribe to them.
    call.remoteParticipants.forEach(remoteParticipant => {
      subscribeToRemoteParticipant(remoteParticipant, dispatch)
    })
    // Subscribe to the call's 'remoteParticipantsUpdated' event to be
    // notified when new participants are added to the call or removed from the call.
    call.on('remoteParticipantsUpdated', e => {
      // Subscribe to new remote participants that are added to the call.
      e.added.forEach(remoteParticipant => {
        subscribeToRemoteParticipant(remoteParticipant, dispatch)
      })
      // Unsubscribe from participants that are removed from the call
      e.removed.forEach(remoteParticipant => {
        console.log('Remote participant removed from the call.')
      })
    })
  } catch (error) {
    console.error(error)
  }
}

// Subscribe to a remote participant obj.
// Listen for property changes and collection udpates.
const subscribeToRemoteParticipant = (remoteParticipant, dispatch) => {
  try {
    // Inspect the initial remoteParticipant.state value.
    console.log(`Remote participant state: ${remoteParticipant.state}`)
    // Subscribe to remoteParticipant's 'stateChanged' event for value changes.
    remoteParticipant.on('stateChanged', () => {
      console.log(`Remote participant state changed: ${remoteParticipant.state}`)
    })

    // Inspect the remoteParticipants's current videoStreams and subscribe to them.
    remoteParticipant.videoStreams.forEach(remoteVideoStream => {
      subscribeToRemoteVideoStream(remoteVideoStream, dispatch)
    })
    // Subscribe to the remoteParticipant's 'videoStreamsUpdated' event to be
    // notified when the remoteParticiapant adds new videoStreams and removes video streams.
    remoteParticipant.on('videoStreamsUpdated', e => {
      // Subscribe to new remote participant's video streams that were added.
      e.added.forEach(remoteVideoStream => {
        subscribeToRemoteVideoStream(remoteVideoStream, dispatch)
      })
      // Unsubscribe from remote participant's video streams that were removed.
      e.removed.forEach(remoteVideoStream => {
        console.log('Remote participant video stream was removed.')
      })
    })
  } catch (error) {
    console.error(error)
  }
}

// Subscribe to a remote participant's remote video stream obj.
// Listen for property changes and collection udpates.
// When their remote video streams become available, display them in the UI.
const subscribeToRemoteVideoStream = async (remoteVideoStream, dispatch) => {
  // Create a video stream renderer for the remote video stream.
  // const videoStreamRenderer = new VideoStreamRenderer(remoteVideoStream)
  let view
  const renderVideo = async () => {
    try {
      // Create a renderer view for the remote video stream.
      if (remoteVideoStream.mediaStreamType === 'ScreenSharing') {
        console.log('Found a screensharing stream')

        dispatch(setIsVideoLoading(true))

        const renderer = new VideoStreamRenderer(remoteVideoStream)
        view = await renderer.createView({ scalingMode: 'Fit' })
        const result = view.target

        // setting as a global variable instead of in state as i  t is not seraliazible and may mess upp the state
        window.$videoView = result

        dispatch(setShowVideo(true))
        // setting on window since its not serializable, reduct complains otherwise

        window.$currentVideoStream = remoteVideoStream

        dispatch(setIsVideoLoading(false))
      }
    } catch (e) {
      console.warn(`Failed to createView, reason=${e.message}, code=${e.code}`)
    }
  }

  remoteVideoStream.on('isAvailableChanged', async () => {
    // Participant has switched video on.
    if (remoteVideoStream.isAvailable) {
      await renderVideo()

      // Participant has switched video off.
    } else {
      if (view) {
        view.dispose()
        view = undefined
      }
    }
  })

  // Participant has video on initially.
  if (remoteVideoStream.isAvailable) {
    await renderVideo()
  }
}

export default sessionSlice.reducer
