import React, { useEffect } from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useSelector, useDispatch } from 'react-redux'
import { createSession, senderDisconnect, shareScreen, setParametersJson, setMuted, showLocalVideo } from '../sessionSlice'
import CircularProgress from '@mui/material/CircularProgress'

import { useCookies } from 'react-cookie'

export default function SenderPanel () {
  const dispatch = useDispatch()
  const pin = useSelector((state) => state.root.session.pin)
  const sessionStatus = useSelector((state) => state.root.session.status)
  const isConnected = useSelector((state) => state.root.session.connected)
  const participantCount = useSelector((state) => state.root.session.participantCount)
  const screenSharingActive = useSelector((state) => state.root.session.screenSharingActive)
  const isConnecting = useSelector((state) => state.root.session.isConnecting)
  const isMuted = useSelector((state) => state.root.session.isMuted)
  const [cookies, setCookie] = useCookies(['user'])

  useEffect(() => {
    // auto connect after first render
    const urlParams = new URLSearchParams(window.location.search)
    const parameterJSON = {}
    urlParams.forEach((value, key) => {
      const keyStr = '' + key
      parameterJSON[keyStr] = value
      console.log(key + ',' + value)
    })
    console.log('Setting cookiePIN first time effect:' + cookies.PIN)
    parameterJSON.cookiePIN = cookies.PIN

    // look for an existing session PIN in client cookie
    console.log('Found a cookie in post render single time effect:' + cookies.PIN)

    dispatch(setParametersJson(parameterJSON))
    dispatch(createSession())
  }, [])

  useEffect(() => {
    // set client session cookie
    console.log('Setting pin in effect')
    setCookie('PIN', pin, { path: '/' })
  }, [pin])

  const doMute = (event) => {
    if (window.$call.isMuted) {
      window.$call.unmute()
      dispatch(setMuted(false))
    } else {
      window.$call.mute()
      dispatch(setMuted(true))
    }
  }

  return (
    <Card sx={{ minWidth: 600 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
          Information and control of your screen sharing session
        </Typography>
        <Typography variant='h5' component='div'>
          {sessionStatus}{isConnecting ? <CircularProgress size={20} /> : ''}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color='text.secondary'>
          Total number of participants {participantCount}
        </Typography>
        <Typography variant='body2'>
          Screen sharing is {screenSharingActive ? 'enabled' : 'disabled'}<br />
          {!screenSharingActive ? 'To enable screen sharing press button below' : ''}
        </Typography>
      </CardContent>
      <CardActions>
        <Button disabled={isConnected || isConnecting} size='small' onClick={() => dispatch(createSession())}>New session</Button>
        <Button disabled={!isConnected || screenSharingActive} size='small' onClick={() => dispatch(shareScreen())}>Share screen</Button>
        {/* <Button disabled={!screenSharingActive} size='small' onClick={() => dispatch(showLocalVideo())}>Show my screen</Button> */}
        <Button disabled={!isConnected} size='small' onClick={() => doMute()}>{isMuted ? 'Unmute' : 'Mute'} </Button>
        <Button disabled={!isConnected} size='small' onClick={() => dispatch(senderDisconnect())}>End session</Button>
      </CardActions>
    </Card>
  )
}
