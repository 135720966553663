import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  typography: {
    fontFamily: ['"Verdana"', 'Open Sans'].join(',')
  },
  palette: {
    mode: 'light'
  }

})

export default theme
