import React, { useEffect } from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { useSelector, useDispatch } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import { joinSession, receiverDisconnect, setPIN, shareScreen, setMuted } from '../sessionSlice'
import { useCookies } from 'react-cookie'

export default function ReceiverPanel () {
  const dispatch = useDispatch()
  const sessionStatus = useSelector((state) => state.root.session.status)
  const pin = useSelector((state) => state.root.session.pin)
  const urlPin = useSelector((state) => state.root.session.urlPin)
  const isConnected = useSelector((state) => state.root.session.connected)
  const participantCount = useSelector((state) => state.root.session.participantCount)
  const isConnecting = useSelector((state) => state.root.session.isConnecting)
  const isMuted = useSelector((state) => state.root.session.isMuted)
  const screenSharingActive = useSelector((state) => state.root.session.screenSharingActive)
  console.log('SessionPanel mute:' + isMuted)
  const [cookies, setCookie] = useCookies(['user'])

  // Dependency array set to empty - this means it will execute after first render and no more times
  useEffect(() => {
    // try joining if there is a PIN set via URL
    if (urlPin != null && urlPin.length > 0) {
      // set client session cookie
      console.log('Setting pin in effect')
      setCookie('PIN', pin, { path: '/' })
      dispatch(joinSession(true))
    } else {
      // check for cookie
      if (cookies.PIN != null && cookies.PIN.length > 0) {
        console.log('Found cookie' + cookies.PIN)
        const cookiePin = cookies.PIN
        dispatch(setPIN(cookiePin))
        dispatch(joinSession())
      }
    }
  }, [])

  const doMute = (event) => {
    if (window.$call.isMuted) {
      window.$call.unmute()
      dispatch(setMuted(false))
    } else {
      window.$call.mute()
      dispatch(setMuted(true))
    }
  }
  const handleInput = (event) => {
    dispatch(setPIN(event.target.value))
    setCookie('PIN', event.target.value, { path: '/' })
  }

  const disconnectClick = (event) => {
    setCookie('PIN', '', { path: '/' })
    dispatch(receiverDisconnect())
  }

  return (
    <Card sx={{ minWidth: 600, maxWidth: 500, minHeight: 250 }}>
      <CardContent>
        <Typography variant='h5' component='div'>
          <div style={{ display: 'inline-flex', gap: '12px' }}>
            <div>{sessionStatus}</div>{isConnecting ? <CircularProgress size={20} /> : ''}
          </div>
        </Typography>
        <Typography sx={{ mb: 1.5 }} color='text.secondary'>
          Total number of participants {participantCount}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
          Enter pin code to join screen sharing session
        </Typography>
        <TextField
          required
          id='outlined-required'
          label='PIN CODE'
          value={pin}
          onChange={handleInput}
        />
      </CardContent>
      <CardActions>
        <Button disabled={isConnected || pin.length === 0 || isConnecting} size='small' onClick={() => dispatch(joinSession(false))}>Connect</Button>
        <Button disabled={!isConnected} size='small' onClick={() => disconnectClick()}>Disconnect</Button>
        <Button disabled={!isConnected} size='small' onClick={() => doMute()}>{isMuted ? 'Unmute' : 'Mute'} </Button>
        <Button disabled={!isConnected || screenSharingActive} size='small' onClick={() => dispatch(shareScreen())}>Share screen</Button>
      </CardActions>
    </Card>
  )
}
