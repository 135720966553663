import * as React from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { useSelector } from 'react-redux'
import QRCode from 'qrcode'
import { useRef, useEffect } from 'react'

export default function JoinInfoPanel () {
  const pin = useSelector((state) => state.root.session.pin)
  const isConnected = useSelector((state) => state.root.session.connected)
  const currentHostName = window.location.host
  const path = window.location.pathname.replace('sender', '')
  const joinURL = window.location.protocol + '//' + currentHostName + path
  const joinURLWithPin = joinURL + '?PIN=' + pin
  const canvasRef = useRef()

  useEffect(() => {
    QRCode.toCanvas(
      canvasRef.current,
      // QR code doesn't work with an empty string
      // so we are using a blank space as a fallback
      joinURLWithPin,
      { scale: 6 },
      (error) => error && console.error(error)
    )
  }, [joinURLWithPin])

  return (
    <Card sx={{ minWidth: 600 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
          Give this information to your participants to be able to join
        </Typography>
        <Typography variant='h5' component='div'>
          {joinURL}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color='text.secondary'>
          PIN code: {pin}
        </Typography>
        <canvas ref={canvasRef} />
        {isConnected
          ? <div>
            <Typography sx={{ mb: 1.5 }} color='text.secondary'>
              Or give them the link below
            </Typography>
            <Typography sx={{ mb: 1.5 }} color='text.secondary'>
              <a href={joinURL + '?PIN=' + pin} target='_blank' rel='noreferrer'>{joinURL}?PIN={pin}</a>
            </Typography>

          </div>

          : ''}
      </CardContent>
      <CardActions />

    </Card>
  )
}
