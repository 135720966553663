import React from 'react'
import SenderPanel from './senderPanel'
import JoinInfoPanel from './joinInfoPanel'
import VideoPanel from '../videoPanel'
import Typography from '@mui/material/Typography'

export default function Sender () {
  let fisk
  return (
    <div>
      <Typography variant='h3' gutterBottom component='div' style={{ color: 'White' }}>
        Screen Sharing
      </Typography>
      <Typography variant='h6' gutterBottom component='div' style={{ color: 'White' }}>
        Disclaimer on usage
      </Typography>
      <div className='container'>
        <SenderPanel />
        <JoinInfoPanel />
        <div className='break' />
        <VideoPanel />
      </div>
      <span />
    </div>
  )
};
